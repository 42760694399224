<template>
	<div class="w-100 bg-mainbg">
		<date-picker
			range
			v-model="dateRange"
			class="mr-3"
			ref="datePicker"
			type="date"
			:clearable="false"
			range-separator=" - "
			format="DD/MM/YYYY"
			@change="changeDate"
			:disabled-date="(date) => date >= new Date()"
		></date-picker>
	</div>
</template>

<script>
export default {
	name: "dateSelector",
	components: {},
	data() {
		return {
			disabledRange: new Date(),
			dateRange: null,
      lastDateRange : null
		};
	},
	methods: {
		changeDate() {
			// if interval of this.dateRange is more than 30 days, show alert
			if (this.dateRange[1] - this.dateRange[0] > 2592000000) {
				alert("Date interval can't be more than 31 days, please select a new date range.");
        this.dateRange = this.lastDateRange;
			} else {
				this.$store.dispatch("global/addDateRange", this.dateRange);
        this.lastDateRange = this.dateRange;
			}
		},
		initialDates() {
			/* this.rangeDate = this.$store.state */ /* [
        new Date(new Date().setDate(new Date().getDate() - 16)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ]; */
			this.dateRange = this.$store.getters["global/dateRange"];
      this.lastDateRange = this.dateRange;
		},
	},
	computed: {},
	created() {
		this.initialDates();
	},
};
</script>

<style>
.mx-datepicker-range {
	width: auto !important;
}
</style>
