<template>
  <div>
    <div id="breadcrumb">
      <!-- <router-view /> -->
      <v-toolbar class="toolbarHeader" absolute color="white" short>
        <div style="height: 100%;" class="d-flex justify-space-between w-100 ml-4 align-center">
          <div style="height: 100%; display: flex; align-items: center;">
            <v-toolbar-title class="titleToolbar">
              {{ title }}
            </v-toolbar-title>
          </div>
          <div >
            <dateSelector v-if="datePickerActive"/>
          </div>
        </div>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dateSelector from "./components/datePicker.vue";
export default {
  name: "Breadcrumb",
  components: { dateSelector },
  data() {
    return {
    };
  },
  methods: {
    ...mapActions("global", ["addDateRange"]),
    /* validateBreadcrumb() {
      if (sessionStorage.modulo === "Listening") {
        return true;
      } else {
        return false;
      }
    }, */
  },
  computed: {
    ...mapGetters("global", {
      title: "getTitlePage",
    }),
  },
  watch: {
    "$store.state.global.datePickerActive": {
      immediate: true,
      handler: function () {
        this.datePickerActive =
          this.$store.getters["global/getDatePickerActive"];
      },
    },
  },
};
</script>

<style>
.toolbarHeader {
  margin-left: 70px;
  margin-top: 60px;
  width: calc(100% - 70px);
}

#main-appbar >>> .v-toolbar__content {
  padding: 0px !important;
}

.titleToolbar{
  font-family: 'Oxygen';
  font-style: normal !important;
  font-weight: 700;
  font-size: 17px !important;
  line-height: 16px !important;
  color: #2C3852;
  height: 40%;
}
</style>