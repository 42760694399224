
import "./set-public-path";
import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import singleSpaVue from "single-spa-vue";
import { store, /* vuetify */ } from "@isc/styleguide";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#00a5ff",
      },
    },
  },
});

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

Vue.component('date-picker', DatePicker);
Vue.config.productionTip = false;
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
